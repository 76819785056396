import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Login extends Component {

    componentDidMount() {
        window.location.replace('https://www.its52.com/Login.aspx?OneLogin=MAZAAR');
    }
   
    render() {
        return <></>;
                
    }
}

export default Login;