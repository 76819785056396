//import axios from 'axios';
import axios from "../../axios-api";

import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("role");
  localStorage.removeItem("role2");
  localStorage.removeItem("username");
  localStorage.removeItem("link");
  localStorage.removeItem("location");
  localStorage.removeItem("locations");
  localStorage.removeItem("photo");
  //localStorage.removeItem("templink");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password, isRememberMe) => {
  return (dispatch) => {
    let authData = {};
    dispatch(authStart());
    if (isRememberMe) {
      localStorage.setItem("LoginID", email);
    } else {
      localStorage.removeItem("LoginID");
    }
    authData = {
      UserName: email,
      Password: password,
      returnSecureToken: true,
      Id : localStorage.temptoken !== "" && localStorage.temptoken !== undefined ? 10 : 0
    };
    const config = {
        headers: { Authorization: `Bearer ${localStorage.temptoken}` },
      };
    //let url = 'http://smms/API/Users/ValidateUser';
    localStorage.temptoken !== "" && localStorage.temptoken !== undefined
      ? axios
          .post("SSO/ValidateUser", authData, config)
          .then((response) => {
            //  if(isKhidmatGuzar)
            //  {
            /// keep this on build
            let role = "";
            let roleList = response.data.user.rList;
            for (var i = 0; i < roleList.length; i++) {
              if (
                (i === 0 && roleList.length === 1) ||
                roleList.length - 1 === i
              ) {
                role = role + roleList[i].roleName;
              } else if (roleList.length > 1 && roleList.length - 1 !== i) {
                role = role + roleList[i].roleName + ", ";
              }
              // else if (roleList.length -1 == i)
              // {

              // }
            }
            const expirationDate = new Date(
              new Date().getTime() + response.data.user.expiresin * 1000
            );
            localStorage.setItem("token", response.data.user.token);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("userId", response.data.user.itsid);
            localStorage.setItem("username", response.data.user.name);
            localStorage.setItem(
              "role",
              JSON.stringify(response.data.user.rList)
            );
            localStorage.setItem("role2", role);
            localStorage.setItem("link", response.data.user.url);
            localStorage.setItem("photo", response.data.user.photo);
            localStorage.removeItem("temptoken");
            localStorage.removeItem("tempuser");
            dispatch(
              authSuccess(response.data.user.token, response.data.user.itsid)
            );
            dispatch(checkAuthTimeout(response.data.user.expiresin));
            
            const config = {
              headers: { Authorization: `Bearer ${localStorage.token}` },
            };
            axios
              .get("MumineenPage/GetLinksForEdit", config)
              .then((res) => {
                if (res.data.locationlist.length > 0) {
                  localStorage.setItem(
                    "locations",
                    JSON.stringify(res.data.locationlist)
                  );
                }
              })
              .catch((err) => {});
          })
          .catch((err) => {
            localStorage.removeItem("temptoken");
            localStorage.removeItem("tempuser");
            dispatch(
              authFail(
                err.response === undefined
                  ? "Server Is Offline"
                  : "Invalid Username or Password"
              )
            );
          })
      : axios
          .post("Users/ValidateUser", authData)
          .then((response) => {
            let role = "";
            let roleList = response.data.user.rList;
            for (var i = 0; i < roleList.length; i++) {
              if (
                (i === 0 && roleList.length === 1) ||
                roleList.length - 1 === i
              ) {
                role = role + roleList[i].roleName;
              } else if (roleList.length > 1 && roleList.length - 1 !== i) {
                role = role + roleList[i].roleName + ", ";
              }
            }
            const expirationDate = new Date(
              new Date().getTime() + response.data.user.expiresin * 1000
            );
            localStorage.setItem("token", response.data.user.token);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("userId", response.data.user.itsid);
            localStorage.setItem("username", response.data.user.name);
            localStorage.setItem(
              "role",
              JSON.stringify(response.data.user.rList)
            );
            localStorage.setItem("role2", role);
            localStorage.setItem("link", response.data.user.url);
            localStorage.setItem("photo", response.data.user.photo);
            localStorage.removeItem("temptoken");
            localStorage.removeItem("tempuser");
            dispatch(
              authSuccess(response.data.user.token, response.data.user.itsid)
            );
            dispatch(checkAuthTimeout(response.data.user.expiresin));

            const config = {
              headers: { Authorization: `Bearer ${localStorage.token}` },
            };
            axios
              .get("MumineenPage/GetLinksForEdit", config)
              .then((res) => {
                if (res.data.locationlist.length > 0) {
                  localStorage.setItem(
                    "locations",
                    JSON.stringify(res.data.locationlist)
                  );
                }
              })
              .catch((err) => {});
          })
          .catch((err) => {
            dispatch(
              authFail(
                err.response === undefined
                  ? "Server Is Offline"
                  : "Invalid Username or Password"
              )
            );
          });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("userId");
        dispatch(authSuccess(token, userId));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

export const authCheckOneLogin = () => {
  return (dispatch) => {
    let authData = {};
    dispatch(authStart());
    // if (isRememberMe) {
    //   localStorage.setItem("LoginID", email);
    // } else {
    //   localStorage.removeItem("LoginID");
    // }
    authData={
      Password : localStorage.itstoken !== "" && localStorage.itstoken !== undefined ? localStorage.itstoken : ""
    }
    // authData = {
    //   UserName: email,
    //   Password: password,
    //   returnSecureToken: true,
    //   Id : localStorage.temptoken !== "" && localStorage.temptoken !== undefined ? 10 : 0
    // };
    // const config = {
    //     headers: { Authorization: `Bearer ${localStorage.temptoken}` },
    //   };
    //let url = 'http://smms/API/Users/ValidateUser';
    localStorage.itstoken !== "" && localStorage.itstoken !== undefined
      ? axios
          .post("Users/OneLogin", authData)
          .then((response) => {
            const expirationDate = new Date(
              new Date().getTime() + response.data.user.expiresin * 1000
            );
            localStorage.setItem("token", response.data.user.token);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("userId", response.data.user.itsid);
            localStorage.setItem("username", response.data.user.name);
            localStorage.setItem("link", response.data.user.url);
            localStorage.setItem("photo", response.data.user.photo);
            // localStorage.removeItem("itstoken");
            localStorage.removeItem("tempuser");
            if (response.data.locationlist.length > 0) {
              localStorage.setItem(
                "locations",
                JSON.stringify(response.data.locationlist)
              );
            }
            // const config = {
            //   headers: { Authorization: `Bearer ${localStorage.token}` },
            // };
            // axios
            //   .get("MumineenPage/GetLinks", config)
            //   .then((res) => {
            //     debugger;
            //     if (res.data.locationlist.length > 0) {
            //       localStorage.setItem(
            //         "locations",
            //         JSON.stringify(res.data.locationlist)
            //       );
            //     }
            //   })
            //   .catch((err) => {});
            dispatch(
              authSuccess(response.data.user.token, response.data.user.itsid)
            );
            dispatch(checkAuthTimeout(response.data.user.expiresin));
            
            
          })
          .catch((err) => {
            localStorage.removeItem("itstoken");
            localStorage.removeItem("tempuser");
            dispatch(
              authFail(
                err.response === undefined
                  ? "Server Is Offline"
                  : "Invalid Username or Password"
              )
            );
          })
          : dispatch(
            authFail("Server Is Offline"
            )
          );
      // : axios
      //     .post("Users/ValidateUser", authData)
      //     .then((response) => {
      //       let role = "";
      //       let roleList = response.data.user.rList;
      //       for (var i = 0; i < roleList.length; i++) {
      //         if (
      //           (i === 0 && roleList.length === 1) ||
      //           roleList.length - 1 === i
      //         ) {
      //           role = role + roleList[i].roleName;
      //         } else if (roleList.length > 1 && roleList.length - 1 !== i) {
      //           role = role + roleList[i].roleName + ", ";
      //         }
      //       }
      //       const expirationDate = new Date(
      //         new Date().getTime() + response.data.user.expiresin * 1000
      //       );
      //       localStorage.setItem("token", response.data.user.token);
      //       localStorage.setItem("expirationDate", expirationDate);
      //       localStorage.setItem("userId", response.data.user.itsid);
      //       localStorage.setItem("username", response.data.user.name);
      //       localStorage.setItem(
      //         "role",
      //         JSON.stringify(response.data.user.rList)
      //       );
      //       localStorage.setItem("role2", role);
      //       localStorage.setItem("link", response.data.user.url);
      //       localStorage.setItem("photo", response.data.user.photo);
      //       localStorage.removeItem("temptoken");
      //       localStorage.removeItem("tempuser");
      //       dispatch(
      //         authSuccess(response.data.user.token, response.data.user.itsid)
      //       );
      //       dispatch(checkAuthTimeout(response.data.user.expiresin));

      //       const config = {
      //         headers: { Authorization: `Bearer ${localStorage.token}` },
      //       };
      //       axios
      //         .get("MumineenPage/GetLinksForEdit", config)
      //         .then((res) => {
      //           if (res.data.locationlist.length > 0) {
      //             localStorage.setItem(
      //               "locations",
      //               JSON.stringify(res.data.locationlist)
      //             );
      //           }
      //         })
      //         .catch((err) => {});
      //     })
      //     .catch((err) => {
      //       dispatch(
      //         authFail(
      //           err.response === undefined
      //             ? "Server Is Offline"
      //             : "Invalid Username or Password"
      //         )
      //       );
      //     });
  };
};
