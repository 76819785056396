import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import "./Spinner.css";

const spinner = () => (
  <div className="loaderOverlay flex justify-content-center align-items-center">
    <ProgressSpinner strokeWidth={4}/>
    {/* <div className="Loader">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div> */}
  </div>
);

export default spinner;
